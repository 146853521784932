// _contexts/SessionProvider.jsx
import React, { useState, useEffect } from "react";
import { SessionContext } from "./SessionContext";
import { useAuth } from "./AuthContext";
import { getSessions } from "../_apis/useGetSessions"; // Import the API call

export const SessionProvider = ({ children }) => {
  const [sessionCount, setsessionCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const [apiAttempted, setApiAttempted] = useState(false);

  useEffect(() => {
    const fetchSessions = async () => {
      if (!currentUser || !currentUser.token) return;
      // console.log("Fetching getSession count for user:", currentUser);
    
      try {
        setLoading(true);
        const response = await getSessions(currentUser.token);
    
        // Extract the session count from the data array
        const sessionCountValue = response.data && response.data[0] ? response.data[0].session_count : null;
    
        setsessionCount(sessionCountValue);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      } finally {
        setApiAttempted(true); // Set apiAttempted to true after the API call
      }
    };
  
    if (currentUser && currentUser.token && currentUser.token_expires_at) {
      fetchSessions();
    }
    
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser && currentUser.token, currentUser && currentUser.token_expires_at]);
  

  return (
    <SessionContext.Provider value={{ sessionCount, loading: loading || !apiAttempted, error }}>
      {children}
    </SessionContext.Provider>
  );
};
