//API INSTANCE
import axiosInstance from './axiosInstance';

export const fetchUserProfile = async (authToken) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_GET_USER_PROFILE}`, {
      headers: {
        
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
