//API INSTANCE
import axiosInstance from './axiosInstance';

export const getSessions = async (authToken) => {
  try {

    // Get the current local date and time
    const localDate = new Date();
    // Set the hours, minutes, and seconds to zero
    localDate.setHours(0, 0, 0, 1);
    // Convert the local date to UTC string format
    const startTime = localDate.toISOString();

    const response = await axiosInstance.post(`${process.env.REACT_APP_GET_SESSIONS_URL}`,
        {
            // Data to be sent in the request body
            start_date: startTime,
         
   
          },
          {
            headers: {
              
              'Authorization': `Bearer ${authToken}`,
            },
          }
        );
    return response.data;
  } catch (error) {
    throw error;
  }
};
