import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from "./reportWebVitals";


import { BrowserRouter } from 'react-router-dom';


// CONTEXTS 

import { AuthProvider } from './_contexts/AuthContext';




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

    <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
    </BrowserRouter>

);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();