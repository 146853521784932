// components/SideBarLeftData.jsx
import React, { useContext } from "react";


import { Button, Container, Modal } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { Alert } from 'react-bootstrap';

// CONTEXTS
import { ScrollContext } from '../../_contexts/ScrollContext';


// STYLES

import "./style.css";

// API CALLS



// IMAGES

import goldStarIcon from "../../assets/img/gold-star-icon.png";
import bullseye from "../../assets/img/bullseye.png";
import lifetimeStar from "../../assets/img/lifepoints-star.png";
import avatar from "../../assets/img/avatar.png";

// COMPONENTS
import { MenuCard } from '../../components/MenuCard';





export const SideBarLeftData = ({ userProfile, loading, error }) => {
  const [targetElementRef] = useContext(ScrollContext);

  const handleScrollClick = () => {
    if (targetElementRef && targetElementRef.current) {
      targetElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

    if (loading) {
      return (
        <div className="loading-box-wrapper">
          <Alert variant="secondary">
            <Alert.Heading>Loading profile...</Alert.Heading>
            <p className="error-text-smaller">This will only take a moment...</p>
          </Alert>
        </div>
      );
    }


    if (error) {
      return (
        <div className='error-box-wrapper'>
          <Alert variant="warning">
            <Alert.Heading>Profile Details Error</Alert.Heading>
            <p>{error.message}</p>
            <p className='error-text-smaller'>We are having trouble loading your profile details. If this problem persists, please contact support at <a href="mailto:support@mysoapbox.com">support@mysoapbox.com</a></p>
          </Alert>
        </div>
      );
    }

    if (!userProfile) {
      return null;
    }

    const pointsThreshold = Number(16000);
    let pointsRemaining = 0;
    let showThresholdStatement = true;

    if (userProfile.total_user_points < pointsThreshold) {
      pointsRemaining = parseInt(
        pointsThreshold - userProfile.total_user_points
      );
    } else {
      showThresholdStatement = false;
    }

    const handleModalClose = () => {
      window.location.href = userProfile.data.profile_survey_url;
    };

    if (userProfile.data.profile_survey_url) {

          return (
        <Modal show={true} onHide={handleModalClose} centered>
          <Modal.Header>
            <Modal.Title>Please Take Profile Survey</Modal.Title>
          </Modal.Header>
          <Modal.Body  style={{ minHeight: '250px', overflowY: 'auto' }}>
            Completing this short profile survey will help us find the best matches for you.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Get Started!
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }

  return (
    <Container fluid>
      <img src={avatar} alt="avatar" id="dashboard-avatar" />
    <h1 id="dashboard-greeting">Good afternoon, {userProfile.data.first_name}!</h1>
        <Row className="mt-4" sm={12} md={12} lg={12}>
        <div id="points-wrapper">
        <img src={goldStarIcon} alt="gold-star-icon" id="gold-icon"/><span id="avail-points-text"> {Intl.NumberFormat().format(userProfile.data.unreedem_user_points)} Points</span>
        </div>
        </Row>
        <div className="text-wrapper">
        <button onClick={handleScrollClick} id="avail-surveys-btn">Your Available Surveys</button>
        </div>
        {showThresholdStatement ? (
        <div className="text-wrapper">
          <p className="mt-2 points-to-gift-text">You are {Intl.NumberFormat().format(pointsRemaining)} points away from redeeming your <span id="gift-card-text">gift card.</span></p>
          <ProgressBar now={10} id="side-bar-left-progress-bar"/>
        </div>
       ) : (
         ''
      )}





        <Row id="side-bar-points-block">

        <Row className="icon-points-text-row">
            <Col className="col-3 align-middle"><img src={bullseye} alt="total-surveys-icon" className="side-bar-points-icons" /></Col>
            <Col className="col-8 align-middle text-height">
                <span className="side-bar-totals">{Intl.NumberFormat().format(userProfile.data.total_survey_completed)}</span>
                <p className="side-bar-small-header">Total Surveys</p>
                <p className="sub-text">Total surveys completed using survey sidekick.</p>
            </Col>
        </Row>
        <Row  className="icon-points-text-row">
            <Col  className="col-3 align-middle"><img src={lifetimeStar} alt="total-surveys-icon" className="side-bar-points-icons" /></Col>
            <Col className="col-8 align-middle text-height">
                <h5 className="side-bar-totals">{Intl.NumberFormat().format(userProfile.data.total_user_points)}</h5>
                <p className="side-bar-small-header">Lifetime Points</p>
                <p className="sub-text">Your total points earned as a MySoapBox member.</p>

            </Col>
        </Row>
        <Row className="icon-points-text-row-mobile">
            <Col className="mobile-icon-col">
              <img src={bullseye} alt="total-surveys-icon" />
            </Col>
            <Col className="mobile-survey-count-col">
                <span>{Intl.NumberFormat().format(userProfile.data.total_survey_completed)}</span>
            </Col>
            <Col className="mobile-text-col">
              <p>Total surveys<br/> completed</p>
            </Col>
        </Row>
        <Row>
          <p className="sub-text-mobile">Total surveys completed using survey sidekick.</p>
        </Row>

        <Row className="icon-points-text-row-mobile">
            <Col className="mobile-icon-col">
              <img src={lifetimeStar} alt="total-surveys-icon" />
            </Col>
            <Col className="mobile-survey-count-col">
                <span>{Intl.NumberFormat().format(userProfile.data.total_user_points)}</span>
            </Col>
            <Col className="mobile-text-col">
              <p>Lifetime<br/> Points</p>
            </Col>
        </Row>
        <Row>
          <p className="sub-text-mobile">Your total points earned as a MySoapBox member.</p>
        </Row>


        <MenuCard totalUserPoints={userProfile.data.total_user_points} />

        </Row>
        </Container>
  );
};
