// components/ReportAuth.jsx
import React, { useState } from 'react';

export const ReportAuth = ({ onAuthenticationSuccess }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const PASSWORD = process.env.REACT_APP_REPORT_PASSWORD; // Use the password from the environment variable
  console.log('Report password from .env:', PASSWORD);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    console.log('Entered password:', password, 'Type:', typeof password);
    console.log('Environment password:', PASSWORD, 'Type:', typeof PASSWORD);
  
    if (password.trim() === PASSWORD.trim()) {
      onAuthenticationSuccess();
    } else {
      setError('Incorrect password');
    }
  };
  

  return (
    <div>
      <h1>Report Authentication</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
};