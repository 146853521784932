import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from "../../_contexts/AuthContext";
import { alertService, AlertType } from '../../_services';



// BOOTSTRAP


import { Modal, Button, Pagination } from 'react-bootstrap';
import { SwitchTransition, Transition } from 'react-transition-group';


// IMAGES

import notificationIcon from "../../assets/img/notification-icon-1.png";

// READ NOTICE PUT REQUEST
import { readNotification } from '../../_apis/useNoticeRead';

// CONTEXTS
import { SessionContext } from '../../_contexts/SessionContext';



// STYLES
import "./style.css";


const propTypes = {
    id: PropTypes.string,
    fade: PropTypes.bool
};

const defaultProps = {
    id: 'default-alert',
    fade: false
};

function Notification({ id, fade }) {

  const { sessionCount } = React.useContext(SessionContext);

    const location = useLocation();
    const { currentUser } = useAuth();
    
    const [alerts, setAlerts] = useState([]);

    const [activeAlertIndex, setActiveAlertIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const alertRef = useRef(null);
    // Check if the device width is in the mobile range (<= 769px)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 769);


    const handlePrevious = () => {
        if (activeAlertIndex > 0) {
          setActiveAlertIndex(activeAlertIndex - 1);
        }
      };
      
      const handleNext = () => {
        if (activeAlertIndex < alerts.length - 1) {
          setActiveAlertIndex(activeAlertIndex + 1);
        }
      };

      const handleClose = () => setShowModal(false);

      useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 769);
        };
      
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
      
      useEffect(() => {
        if (isMobile && alerts.length > 0 && (sessionCount === null || sessionCount < 25)) {
          setShowModal(true);
        }
      }, [alerts, isMobile, sessionCount]);
      
      

    useEffect(() => {
        // subscribe to new alert notifications
        const subscription = alertService.onAlert(id)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    setAlerts(alerts => {
                        // filter out alerts without 'keepAfterRouteChange' flag
                        const filteredAlerts = alerts.filter(x => x.keepAfterRouteChange);

                        // remove 'keepAfterRouteChange' flag on the rest
                        filteredAlerts.forEach(x => delete x.keepAfterRouteChange);
                        return filteredAlerts;
                    });
                } else {
                    // add alert to array
                    setAlerts(alerts => ([...alerts, alert]));

                    // auto close alert if required
                    if (alert.autoClose) {
                        setTimeout(() => removeAlert(alert), 3000);
                    }
                }
            });

        // clear alerts on location change

        

        
       
        alertService.clear(id);
       

        // clean up function that runs when the component unmounts
        return () => {
            // unsubscribe & unlisten to avoid memory leaks
            subscription.unsubscribe();
            
        }; // eslint-disable-next-line 
    }, [location]);

    function removeAlert(alert, notice_id) {
        if (fade) {
          // fade out alert
          const alertWithFade = { ...alert, fade: true };
          setAlerts((alerts) => alerts.map((x) => (x === alert ? alertWithFade : x)));
      
          // remove alert after faded out
          setTimeout(() => {
            readNotification(notice_id);
            setAlerts((alerts) => alerts.filter((x) => x !== alertWithFade));
            setActiveAlertIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : 0
            );
          }, 250);
        } else {
          // remove alert
          // console.log("Removing alert", notice_id);
          readNotification(currentUser.token, notice_id);
          setAlerts((alerts) => alerts.filter((x) => x !== alert));
          setActiveAlertIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : 0
          );
        }
      }

    function cssClasses(alert, isMobile) {
        if (!alert) return;
        
        const classes = ['show'];
                
        const alertTypeClass = {
            [AlertType.Success]: 'alert alert-success',
            [AlertType.Error]: 'alert alert-danger',
            [AlertType.Info]: 'alert alert-info',
            [AlertType.Warning]: 'alert alert-warning'
        }

        classes.push(alertTypeClass[alert.type]);

        if (alert.fade) {
            classes.push('fade');
        }

        if (isMobile) {
          classes.push(...['alert']);
        } else {
          classes.push(...['alert', 'alert-dismissable', 'mobile-alert']);
        }

        return classes.join(' ');
    }


      

    if (!alerts.length) return null;

 




    const desktopAlerts = (state) => (
      <div
        ref={alertRef}
        className={`notice-style alert-content ${cssClasses(currentAlert)} ${
          state === 'entering'
            ? 'alert-enter'
            : state === 'entered'
            ? 'alert-enter-active'
            : state === 'exiting'
            ? 'alert-exit'
            : 'alert-exit-active'
        }`}
      >
      <img src={notificationIcon} alt="notice-icon" id="notice-icon" />
      <span className="text-muted">
        ({activeAlertIndex + 1} of {alerts.length})
      </span>{" "}
      <span
        id="notice-text"
        dangerouslySetInnerHTML={{
          __html: `${currentAlert.message} (Survey ID: ${currentAlert.survey_id})`,
        }}
      />
      <Button
        onClick={() => removeAlert(currentAlert, currentAlert.notice_id)}
        variant="light"
        className="float-end ml-10 mr-0"
      >
        <span id="accept-text">Accept</span>
      </Button>
    </div>
  );

  const mobileAlerts = (state) => (
    <div
      ref={alertRef}
      className={`notice-style ${cssClasses(currentAlert)} ${
        state === 'entering'
          ? 'alert-enter'
          : state === 'entered'
          ? 'alert-enter-active'
          : state === 'exiting'
          ? 'alert-exit'
          : 'alert-exit-active'
      }`}
    >
    <div id="mobile-alert-icon"><img src={notificationIcon} alt="notice-icon" id="notice-icon-mobile" />    </div>

    <p
      id="notice-text"
      dangerouslySetInnerHTML={{
        __html: `${currentAlert.message}<br/> (Survey ID: ${currentAlert.survey_id})`,
      }}
    />
    <div className="mobile-text-muted"> 
    Notice: ({activeAlertIndex + 1} of {alerts.length})
    </div>{" "}

  </div>
);

  const renderDesktopAlerts = () => (
    <SwitchTransition>
      <Transition
        key={activeAlertIndex}
        timeout={100}
        mountOnEnter
        unmountOnExit
      >
        {(state) => desktopAlerts(state)}
      </Transition>
    </SwitchTransition>
  );

  const renderMobileAlerts = () => (
    
    <Modal
      show={showModal}
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: '250px', overflowY: 'auto' }}>
        <SwitchTransition>
          <Transition
            key={activeAlertIndex}
            timeout={100}
            mountOnEnter
            unmountOnExit
          >
            {(state) => mobileAlerts(state)}
          </Transition>
        </SwitchTransition>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => removeAlert(currentAlert, currentAlert.notice_id)}
          variant="light"
        >
          <span id="accept-text">Accept</span>
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>

  );
  
      
  const currentAlert = alerts[activeAlertIndex];

  return (
  <div className="container">
    {isMobile ? renderMobileAlerts() : renderDesktopAlerts()}
    {!isMobile && alerts.length > 1 && (
      <div className="d-flex justify-content-center notice-nav">
        <Pagination>
          <Pagination.Prev
            onClick={handlePrevious}
            disabled={activeAlertIndex === 0}
          >
            &laquo;
          </Pagination.Prev>
          <Pagination.Next
            onClick={handleNext}
            disabled={activeAlertIndex === alerts.length - 1}
          >
            &raquo;
          </Pagination.Next>
        </Pagination>
      </div>
    )}
  </div>

  );
      }

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;
export { Notification };