// axiosInterceptor.js
export const useAxiosInterceptor = (axiosInstance, navigate) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        navigate('/auth');
      }
      return Promise.reject(error);
    }
  );
};
