// _apis/useFetchSurveyLink.js
import { useState, useCallback } from 'react';

import { useAuth } from "../_contexts/AuthContext";
import { useNavigate } from 'react-router-dom'; 

//API INSTANCE
import axiosInstance from './axiosInstance';

const useFetchSurveyLink = () => {
  const [loading, setLinkLoading] = useState(true);
  const [error, setLinkError] = useState(null);
  const { currentUser } = useAuth();
  const authToken = currentUser ? currentUser.token : null;


  const fetchLink = useCallback(async (surveyId) => {
    if (authToken) {
      setLinkLoading(true);
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_GET_SURVEY_LINK_API_URL}?opportunities_id=${surveyId}`, {
          headers: {
  
            "Authorization": `Bearer ${authToken}`,
          },
        });

        setLinkLoading(false);
        return response.data.data.link;
      } catch (error) {
        setLinkError(error);
        setLinkLoading(false);

        return null;
      }
    }
    return null;
  }, [authToken]); 

  return { loading, error, fetchLink };
};

export default useFetchSurveyLink;

