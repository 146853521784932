// ProtectedRoute.jsx
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../_contexts/AuthContext';

export const ProtectedRoute = () => {
    const { currentUser } = useAuth();

    console.log(`Current User: ${currentUser}`);

    if (currentUser === undefined) {
      return null; // Show nothing while checking the authentication state
    }

    if (currentUser === null || !currentUser.isLoggedIn) {
      return <Navigate to="/auth" replace />;
    }

    return <Outlet />;
  };
