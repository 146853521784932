// useAuthUser.js (this file) is a custom hook that is used to authenticate the user
import { useState, useEffect } from 'react';
import axiosInstance from './axiosInstance';





const useAuthUser = (userId, t, kinesis_auth) => {
  const [authState, setAuthState] = useState({ status: 'idle', message: null, token: null, token_expires_at: null });



  useEffect(() => {
    if (!userId || !t || !kinesis_auth) return;



    const token = localStorage.getItem('token');
    const tokenExpiresAt = localStorage.getItem('token_expires_at');

    // Check if the token is still valid before making a request
    if (token && tokenExpiresAt && Date.now() < parseInt(tokenExpiresAt, 10)) {
      setAuthState({ status: 'success', token });
      return;
    }

    const authorizeFromBackend = async () => {
      try {
        setAuthState({ status: 'loading', token: null, token_expires_at: null });
        const response = await axiosInstance.get(`${process.env.REACT_APP_AUTH_API_URL}`, {
          params: { id: userId, t: t, kinesis_auth: kinesis_auth },
        });
        return response;
      } catch (error) {
        // if the response is a 404 not found, that could potentially mean a couple of things:
        // user is not found
        // user is found but inactive or deleted
        // a SurveyExclusion object was not found
        // see: yms_app/views/login.py
        //
        if (error.response.status === 404) {
          setAuthState({ status: 'forbidden', token: null, token_expires_at: null });
        } else {
          const message = `${error.response.status} : ${error.response?.message}`;
          setAuthState({ status: 'error', message: message, token: null, token_expires_at: null });
        }
      }
    };

    const authenticate = async () => {
      const response = await authorizeFromBackend();
      if (response === undefined) {
        return;
      }

      console.log(`the response data ${response.data.data}`);
      const accessToken = response.data.data.token.access_token;
      const expiresIn = parseInt(response.data.data.token.expires_in, 10);
      const expiresAt = Date.now() + expiresIn * 1000;

      localStorage.setItem('token', accessToken);
      localStorage.setItem('token_expires_at', expiresAt);
      setAuthState({ status: 'success', token: accessToken, token_expires_at: expiresAt });
      };


    authenticate();
  }, [userId, t, kinesis_auth]);

  return authState;
};

export default useAuthUser;
