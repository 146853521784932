// SurveyColumn.jsx 

// REACT BOOTSTRAP
import React, { useContext, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';

// COMPONENTS

import { SurveyList } from '../../components/SurveyList';
import { UserProfileSetup } from "../UserProfileSetup";

// CONTEXT
import { ScrollContext } from '../../_contexts/ScrollContext';


// IMAGES



// STYLES
import "./style.css";



export const SurveyColumn = () => {

  const [, setTargetElementRef] = useContext(ScrollContext);

  const targetElementRef = useRef(null);

  useEffect(() => {
    setTargetElementRef(targetElementRef);
  }, [setTargetElementRef]);
  return (
    <Container className="survey-column-wrapper"fluid >
        {/* <UserProfileSetup /> */}


        <Row>
            <Card className="survey-list-card"  ref={targetElementRef}>
                <Card.Body>
                    <h4 id="all-surveys">Available Survey's</h4>
  
      

                    <SurveyList/>
               
                    

                </Card.Body>
            </Card>
        </Row>
 
    </Container>
    
  )
}
