// REACT BOOTSTRAP
import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

// COMPONENTS
import { Notification } from "../Notification/Notification.jsx";


// IMAGES
import notificationIcon from "../../assets/img/notification-icon.png";
import notificationIconLeft from "../../assets/img/notification-icon-2.png"
import notificationBackground from "../../assets/img/bg-header.png";

import mobileBackground from "../../assets/img/bg-mobile-header.png";


// STYLES
import "./style.css";




export const NotificationBox = ({ userNotices, loading, error }) => {
  return (
    
    <div className={"dashboard-home-bg"}>

            {/* notification banner above survey list */}
            <div className="notice-wrapper">
                <div className="container align-middle">
                    <Notification />
                </div>    
           
            </div>
        
      
    </div>
    
      
    
  )
}





