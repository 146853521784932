// PageNotFound.js

import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from React Router v6

import styles from "./style.module.css";

export const PageNotFound = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/'); // Function to navigate to the home page
  };

  return (
    <Container className={styles['page-not-found-wrapper']}>
      <div className={styles['page-not-found-content']}>
        <h1>404 - This Page Not Found!</h1>
        <p>The page you are looking for does not exist.</p>
        <Button variant="secondary" className={styles['btn-home']} onClick={goToHome}>
          Go Home
        </Button>
      </div>
    </Container>
  );
};
