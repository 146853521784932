// components/SessionCountModal.jsx
import React, { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { SessionContext } from '../../_contexts/SessionContext';

import './style.css';

export const SessionLimitModal = () => {
  const { sessionCount } = useContext(SessionContext);
  const showModal = sessionCount && sessionCount >= 25;

  const handleClose = () => {
    window.location.href = 'https://www.mysoapbox.com/page/1639?page=history';
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Session Limit Reached</Modal.Title>
      </Modal.Header>
      <Modal.Body  style={{ minHeight: '250px', overflowY: 'auto' }}>
        You have reached the maximum allowed sessions for today. Keep in mind that we have limited sessions during this phase. Make every survey count by providing truthful and complete answers. During this phase we are allowing our users up to 25 sessions per day. Please return tomorrow to continue using the application. Thank you for participating!
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

