// Dashboard.jsx
// REACT
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// STYLES
import "./style.css";
import styles from "./style.module.css";
import "../../assets/global.css";

// APIS



// COMPONENTS
import { Layout } from '../../layouts/Layout';
import { NotificationBox } from '../../components/NotificationBox';
import { SideBarLeft } from '../../components/SideBarLeft';
import { SurveyColumn } from '../../components/SurveyColumn';

export const Dashboard = () => {

  return (

    <Layout>

     <NotificationBox/>

     <div className={styles['flex-container']}>
      <div className="side-bar-container">
        <SideBarLeft />
      </div>
      <div className={styles['survey-column-container']}>
        <SurveyColumn />
      </div>
    </div>
    </Layout>

  );
};
