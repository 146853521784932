// components/Auth.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthUser from '../../_apis/useAuthUser';
import { useAuth } from '../../_contexts/AuthContext';

//BOOTSTRAP
import { Modal, Button } from 'react-bootstrap';


const handleModalClose = () => {
  window.location.assign(`${process.env.REACT_APP_NOT_BETA_URL}`);
};

// This is the page that handles the redirect after a person signs into kinesis
//
export const Auth = () => {
  const navigate = useNavigate();
  const { setCurrentUser } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get('id');
  const t = urlParams.get('t');
  const kinesis_auth = urlParams.get('kinesis_auth');
  const authState = useAuthUser(userId,t,kinesis_auth);

  // NON-BETA USER ERROR MODAL
  const [showModal, setShowModal] = useState(false);




  useEffect(() => {
    if (!userId || !t || !kinesis_auth) {
      window.location.assign(`${process.env.REACT_APP_AUTH_REDIRECT_URL}`);
      return;
    }

    if (authState.status === 'forbidden') {
      setShowModal(true);
    } else if (authState.status === 'success') {
      setCurrentUser({ isLoggedIn: true, token: authState.token, token_expires_at: authState.token_expires_at });
      localStorage.setItem('userId', userId);
      navigate('/');
    } else if (authState.status === 'error') {
      setCurrentUser(null);
      console.log(`Authorization error: ${authState.message}`);
      // window.location.assign(`${process.env.REACT_APP_AUTH_REDIRECT_URL}`);
    }
  }, [userId, t, kinesis_auth, authState, navigate, setCurrentUser]);

  if (authState.status === 'loading') {
    // console.log('loading');
    return <div>Authenticating...</div>;
  }

return (
  <>
    {showModal && (
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apologies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You do not currently have access to this application. If you feel you have received this message in error, please contact support at support@mysoapbox.com.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalClose}>
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </>
);
};
