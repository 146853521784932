import React, { useContext } from "react";
import { SurveyListContext } from "../../_contexts/SurveyListContext";

export const ReportGenerator = () => {
  const { surveyList = { data: [] } } = useContext(SurveyListContext);

  if (!surveyList.data.length) {
    return <div>Loading...</div>;
  }

  let total_surveys = 0;
  let total_questions = 0;
  let total_profile_datapoints = 0;
  let total_non_profile_datapoints = 0;
  let total_surveys_without_profile_questions = 0;

  surveyList.data.forEach((survey) => {
    total_surveys++;

    const survey_qualifications = Object.values(survey.survey_qualifications);
    const profile_questions = survey_qualifications.filter((q) => q.question_id === 42);
    const non_profile_questions = survey_qualifications.filter((q) => q.question_id !== 42);

    total_questions += survey_qualifications.length;
    total_profile_datapoints += profile_questions.length;
    total_non_profile_datapoints += non_profile_questions.length;

    if (profile_questions.length === 0) {
      total_surveys_without_profile_questions++;
    }
  });

  return (
    <div>
      <h2>Report</h2>
      <p>Total Surveys: {total_surveys}</p>
      <p>Total Questions: {total_questions}</p>
      <p>Total Profile Datapoints: {total_profile_datapoints}</p>
      <p>Total Non-Profile Datapoints: {total_non_profile_datapoints}</p>
      <p>Total Surveys Without Profile Questions: {total_surveys_without_profile_questions}</p>
    </div>
  );
};
