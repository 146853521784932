// UserProfileProvider
import React, { useState, useEffect } from "react";
import { NotificationsContext } from "./NotificationsContext";
import { useAuth } from "./AuthContext";
import { fetchNotifications } from "../_apis/useGetNotification";

// SERVICES

import { alertService } from '../_services';// Import the API call

export const NotificationsProvider = ({ children }) => {
  const [userNotices, setNotice] = useState(null);
  const [loading, setNoticeLoading] = useState(true);
  const [error, setNoticeError] = useState(null);
  const { currentUser } = useAuth();
  const [apiAttempted, setApiAttempted] = useState(false);



  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser || !currentUser.token) return;
      // console.log("Fetching data in NotificationsProvider with currentUser:", currentUser);
  
      try {
        setNoticeLoading(true);
        const data = await fetchNotifications(currentUser.token);
        setNotice(data);
        setNoticeLoading(false);
      } catch (error) {
        setNoticeError(error);
        setNoticeLoading(false);
      } finally {
        setApiAttempted(true); // Set apiAttempted to true after the API call
      }
    };
  
   if (currentUser && currentUser.token && currentUser.token_expires_at) {
      fetchData();
    }//eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser && currentUser.token, currentUser && currentUser.token_expires_at]);

  useEffect(() => {
    if (userNotices && userNotices.data) {
      userNotices.data.forEach(item => {
        let notice_id = item.id
        let survey_id = item.survey_id
        if (!item.is_read && item.message !== null) {
          alertService.warn(notice_id, item.message, {}, survey_id);
          // console.log(notice_id, item.message, survey_id);
        }
      });
    }
  }, [userNotices]);
  
  

  return (
    <NotificationsContext.Provider value={{ userNotices, loading: loading || !apiAttempted, error }}>
      {children}
    </NotificationsContext.Provider>
  );
};
