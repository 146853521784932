// SideBarLeftDataWrapper.jsx
import React, { useContext } from 'react';
import { UserProfileContext } from '../../_contexts/UserProfileContext';
import { SideBarLeftData } from '../../components/SideBarLeftData';


export const SideBarLeftDataWrapper = () => {
  const { userProfile, loading, error } = useContext(UserProfileContext);

  return (
    <SideBarLeftData userProfile={userProfile} loading={loading} error={error} />

  );
};
