import axiosInstance from './axiosInstance';

export const fetchNotifications = async (authToken) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_GET_NOTIFICATIONS}`, {
      headers: {
        
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


