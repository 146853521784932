import React from 'react'

import { Header } from '../../components/Header/Header';

import { Footer } from '../../components/Footer/Footer';


import Container from 'react-bootstrap/Container';






export const Layout = ({ children }) => {
  return (
    <React.Fragment>
    <Container fluid className='page-wrapper'>
    <Header />
    
      {children}
    
    </Container>
    <Footer/>

    </React.Fragment>
   

  );
};

