//API INSTANCE
import axiosInstance from './axiosInstance';

export const readNotification = async (authToken, notice_id) => {
  try {
    const response = await axiosInstance.put(`${process.env.REACT_APP_PUT_NOTICE_READ}`,
        {
            // Data to be sent in the request body
            is_read: true,
            // is_deleted: true,
            action: "read",
            notifications: [notice_id],
          },
          {
            headers: {
              
              'Authorization': `Bearer ${authToken}`,
            },
          }
        );
    return response.data;
  } catch (error) {
    throw error;
  }
};
