import { useState } from 'react';

//API INSTANCE
import axiosInstance from './axiosInstance';

export const useRegisterNewUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const register = async (options) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_REGISTER_USER_URL}`, {
        first_name: options.firstName,
        last_name: options.lastName,
        email: options.email,
        confirm_email: options.confirmEmail,
        password: options.password,
        confirm_password: options.confirmPassword,
        user_winning_choice: options.userWinningChoice,
        user_meta: {
            dob: options.dob,
            country: options.country,
            zip_code: options.zipCode,
            recruit_url: options.userRecruitURL,
            terms_accepted: options.agreedToTerms,
          }


      });
      setLoading(false);
      return response.data;
    } catch (err) {
      setError(err);
      setLoading(false);
      return null;
    }
  };

  return { loading, error, register };
};
