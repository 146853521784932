import axiosInstance from './axiosInstance';

export const fetchLogout = async (authToken) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_GET_LOGOUT}`, {
      headers: {
        
        "Authorization": `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

