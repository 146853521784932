import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

// ICON IMAGES
import redeemIcon from "../../assets/img/redeem-icon-blue.png";
import historyIcon from "../../assets/img/history-icon.png";
import referIcon from "../../assets/img/refer-icon.png";
import editProfileIcon from "../../assets/img/gear.png";
import howIcon from "../../assets/img/how-to-icon.png";
import burgerMenu from "../../assets/img/mobile-hamburger-menu.png";


// STYLES

import "./style.css";

export const MenuMobile = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="offcanvas-mobile-menu">
    <img src={burgerMenu} onClick={handleShow} alt="dropdown-menu" id="dropdown-menu"/>
      


    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
       
      </Offcanvas.Header>
      <Offcanvas.Body>
   
          <div className="menu-icons menu-mobile">
            <Row className="link-row">
              <Col className="icon-col">
                <span>
                  <img src={redeemIcon} alt="" />
                </span>
              </Col>
              <Col className="left-menu-link-col">
                <a href={`https://www.mysoapbox.com/page/1639?page=redeem`}>Redeem</a>
              </Col>
            </Row>
            <Row className="link-row">
              <Col className="icon-col">
                <span>
                  <img src={historyIcon} alt="" />
                </span>
              </Col>
              <Col className="left-menu-link-col">
                <a href={`https://www.mysoapbox.com/page/1639?page=history`}>Survey History</a>
              </Col>
            </Row>
            <Row className="link-row">
              <Col className="icon-col">
                <span>
                  <img src={referIcon} alt="" />
                </span>
              </Col>
              <Col className="left-menu-link-col">
                <a href={`https://www.mysoapbox.com/page/1639?page=refer`}>Refer-a-Friend</a>
              </Col>
            </Row>
            <hr />
            <Row className="link-row">
              <Col className="icon-col">
                <span>
                  <img src={editProfileIcon} alt="" />
                </span>
              </Col>
              <Col className="left-menu-link-col">
                <a href={`https://www.mysoapbox.com/page/1639?page=profile`}>Edit Profile</a>
              </Col>
            </Row>
            <Row className="link-row">
              <Col className="icon-col">
                <span>
                  <img src={howIcon} alt="" />
                </span>
              </Col>
              <Col className="left-menu-link-col">
                <a href={`https://www.mysoapbox.com/page/1639?page=faq`}>How MySoapBox Works</a>
              </Col>
            </Row>
          </div>
    
      </Offcanvas.Body>
    </Offcanvas>
    </div>

  );
};