import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Container, Modal } from "react-bootstrap";
import { useRegisterNewUser } from "../../_apis/useRegisterNewUser";
import ReCAPTCHA from "react-google-recaptcha";
import "./style.css";

export const FormRegisterNewUser = () => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [userRecruitURL, setRecruitURL] = useState("");
  const { loading, error, register } = useRegisterNewUser();

  const [registrationSuccess, setRegistrationSuccess] = useState(null);
  const [userWinningChoice, setUserWinningChoice] = useState("")

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaTimeout, setRecaptchaTimeout] = useState(null);



  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // Initial setup to get the recruitment URL
  useEffect(() => {
    setRecruitURL(window.location.href);
  }, []);

    //  --> Validations <-- //
  // Regex for US zip code validation - 5 digits (ex.92107)

  const validateUSZipCode = (zipCode) => {
    const regex = /^\d{5}$/;
    return regex.test(zipCode);
  };

  // Regex for Canada zip code validation - 6 characters (ex. A1A 1A1)
  const validateCAZipCode = (zipCode) => {
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    return regex.test(zipCode);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!recaptchaValue) {
        alert("Please complete the reCAPTCHA.");
        return;
      }

    clearTimeout(recaptchaTimeout);

    if (userWinningChoice) {
        return;
    }

    if (!agreedToTerms) {
      alert("Please select the checkbox to acknowledge that you reviewed and agreed to the Terms of Use and Privacy Policy.");
      return;
    }

    if (country === 'US' && !validateUSZipCode(zipCode)) {
        alert("Please enter a valid US zip code.");
        return;
      }

    if (country === 'CA' && !validateCAZipCode(zipCode)) {
        alert("Please enter a valid Canadian postal code.");
        return;
      }

    if (email !== confirmEmail) {
        alert('Email and Confirm Email do not match.');
        return;
      }

    if (password !== confirmPassword) {
        alert('Password and Confirm Password do not match.');
        return;
      }

      const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    // Password complexity check using regex
    if (!passwordRegex.test(password)) {
        alert('Your password must be at least 8 characters long and contain: an uppercase letter, a lowercase letter, a number, and a special character (like punctuation.)');
        return;
      }



    const response = await register({
        firstName: firstName,
        lastName: lastName,
        dob: dob,
        country: country,
        zipCode: zipCode,
        email: email,
        password: password,
        confirmEmail: confirmEmail,
        confirmPassword: confirmPassword,
        userRecruitURL: userRecruitURL,
        agreedToTerms: agreedToTerms,
        userWinningChoice: userWinningChoice
      });

      if (response) {
        setRegistrationSuccess(true);
        setShow(true); // Set the registration Congrats modal show state to true
    } else if (error) {
        setRegistrationSuccess(false);
        setShow(true); // Set the registration Congrats modal show state to false
    }



  };

  // Function to handle recaptcha change and set a timeout to invalidate the recaptcha value after 2 minutes (120000 milliseconds)
  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaTimeout(setTimeout(() => setRecaptchaValue(null), 120000));
  };

  // Get the placeholder text for the zip code field based on the country selected
  const getZipCodePlaceholder = (countryCode) => {
    switch (countryCode) {
      case 'US':
        return 'Zip Code';
      case 'CA':
        return 'Postal Code';
      default:
        return 'Zip Code';
    }
  };

  return (

    <Container fluid>
      {registrationSuccess === null ? (
        <>
          {error && <Alert variant="danger"  aria-live="assertive" role="alert">Registration failed: {error.message}</Alert>}

            <Form onSubmit={handleSubmit} className="registration-form">
            <Form.Group controlId="formUserWinningChoice" style={{ display: 'none' }}>
                <Form.Control
                value={userWinningChoice}
                onChange={e => setUserWinningChoice(e.target.value)}/>
            </Form.Group>
        <Form.Group controlId="formFirstName">
        <Form.Label className="sr-only" aria-label="First Name">First Name</Form.Label>

          <Form.Control
            value={firstName}
            placeholder="First Name"
            maxLength={50}
            onChange={e => setFirstName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formLastName">
        <Form.Label className="sr-only" aria-label="Last Name">Last Name</Form.Label>
          <Form.Control
            value={lastName}
            placeholder="Last Name"
            maxLength={60}
            onChange={e => setLastName(e.target.value)}
            required
          />
        </Form.Group>


        <Form.Group controlId="formDOB">
        <Form.Label className="sr-only" aria-label="Date of Birth">Date of Birth</Form.Label>
          <Form.Control
            type="date"
            placeholder="Date of Birth"
            value={dob}
            onChange={e => setDob(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formCountry">
        <Form.Label className="sr-only" aria-label="Country">Country</Form.Label>
          <Form.Control as="select" value={country} onChange={e => setCountry(e.target.value)} required
    >
        <option value="" disabled>Select Country</option>
            <option value="US">USA</option>
            <option value="CA">Canada</option>
            {/* Add more countries as needed */}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formZipCode">
        <Form.Label className="sr-only" aria-label={getZipCodePlaceholder}>Zipcode</Form.Label>
          <Form.Control
            value={zipCode}
            placeholder={getZipCodePlaceholder(country)}
            onChange={e => setZipCode(e.target.value)}
            maxLength={country === 'US' ? 5 : country === 'CA' ? 7 : 0}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
        <Form.Label className="sr-only" aria-label="Email">Email</Form.Label>
          <Form.Control type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} required />
        </Form.Group>

        <Form.Group controlId="formConfirmEmail">
        <Form.Label className="sr-only" aria-label="Confirm Email">Confirm Email</Form.Label>
          <Form.Control type="email" placeholder="Confirm Email" value={confirmEmail} onChange={e => setConfirmEmail(e.target.value)} required />
        </Form.Group>

        <Form.Group controlId="formPassword">
        <Form.Label className="sr-only" aria-label="Password">Password</Form.Label>
          <Form.Control type="password"  placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} required />
        </Form.Group>

        <Form.Group controlId="formConfirmPassword">
        <Form.Label className="sr-only" aria-label="Confirm Password">Confirm Password</Form.Label>
          <Form.Control type="password"  placeholder="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
        </Form.Group>

        <Form.Group controlId="formAgreeToTerms" className="terms-field-wrapper">
        <Form.Label className="sr-only" aria-label="Agree to Terms and Privacy Policy">Agree to Terms and Privacy Policy</Form.Label>
            <Form.Check
              type="checkbox"
              label={
                <p>
                  By completing this registration, I acknowledge that I reviewed and agreed to your
                  <a href="/terms-of-use" target="_blank" rel="noopener noreferrer"> Terms of Use</a> and
                  <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
                  I also agree to receive periodic emails from MySoapBox with offers and promotional marketing messages.
                  <span className="info-icon" aria-label="Information" role="img">ⓘ</span>
                </p>
              }
              onChange={() => setAgreedToTerms(!agreedToTerms)}
              required
            />
          </Form.Group>
          <Form.Group>
          <ReCAPTCHA
          // TODO: Replace with real sitekey
            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={handleRecaptchaChange}
          />
        </Form.Group>



        <Button variant="primary" type="submit" disabled={loading} className="reg-btn">
          {loading ? "Registering..." : "Complete Registration"}
        </Button>
        </Form>

        </>
      ) : (
        // TODO: Refactor each Modal into its own component.
        // TODO: Long term probably even have a Modal component that can be reused for all modals depending on style needs
        // TODO: Style modals to match the rest of the site
        // TODO: remove alert() and replace with modal
        // TODO: Once the user has registered and the modal closed, alter the reg page to the home pagelook or redirect to home page?
        // TODO: Backend to implement rate-limiting to prevent brute force attacks.
        // TODO: Backend to log and monitor repeated failed attempts from the same IP.

        <div>
    {registrationSuccess ? (
      <Modal show={show} onHide={handleClose} aria-labelledby="modalTitleSuccess" role="dialog" aria-modal="true">
        <Modal.Header closeButton>
          <Modal.Title id="modalTitleSuccess">Congratulations!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You're almost there {firstName}!<br/><br/>We have sent you an email to {email}. Please confirm your email address to complete your registration.<br /><br />If you don't see it check your Spam or Junk folder. For Gmail, check your 'Promotions' folder.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} id="modalTitleSuccess">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    ) : (
      <Modal show={show} onHide={handleClose} aria-labelledby="modalTitleError" role="dialog" aria-modal="true">
        <Modal.Header closeButton>
          <Modal.Title id="modalTitleError">Something went wrong...</Modal.Title>
        </Modal.Header>
        <Modal.Body>We are sorry, but something went wrong. Please try again later.</Modal.Body>
        <Modal.Footer>
        <Form.Group>
          <ReCAPTCHA
            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
            onChange={handleRecaptchaChange}
          />
        </Form.Group>
          <Button variant="secondary" onClick={handleClose} id="modalTitleError">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </div>
)}
    </Container>
  );
};
