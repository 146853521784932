// REACT BOOTSTRAP
import React, { useState } from "react";
import { SideBarLeftDataWrapper } from '../../components/SideBarLeftDataWrapper';
import "./style.css";





export const SideBarLeft = () => {
  return (
        <SideBarLeftDataWrapper/>

  );
};


