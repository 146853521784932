import React from 'react'

import { Header } from '../../components/Header/Header';

import { Footer } from '../../components/Footer/Footer';


import Container from 'react-bootstrap/Container';






export const RegistrationLayout = ({ children }) => {
  return (
    <React.Fragment>
    <Container fluid className='page-wrapper' id='reg-page-wrapper'>


      {children}

    </Container>
    <Footer/>

    </React.Fragment>


  );
};
