// Registration.jsx
// REACT
import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// STYLES
import "./style.css";
import styles from "./style.module.css";
import "../../assets/global.css";

// APIS



// COMPONENTS
import { RegistrationLayout } from '../../layouts/RegistrationLayout';
// Hidden for now, however, may use this notificiation service still
// import { NotificationBox } from '../../components/NotificationBox';
import { FormRegisterNewUser } from '../../components/FormRegisterNewUser';



export const Registration = () => {

  return (

    <RegistrationLayout>

     <div className="page-content">
        <div className={styles['flex-container']}>


        <Row className="top-row">
            <Col className="top-row-left">
            <img alt="" src={require("../../assets/img/logo-menu-1.png")} className={"reg-logo"} />
            <h3><span id="take-surveys">Take Surveys,</span> <span id="get-cards">Get Gift Cards.</span></h3>
            <p>Become a member of MySoapBox™. We collect feedback on the products and services you use every day. You collect points redeemable for gift cards at your favorite stores and restaurants! <br/><br/><b>Fill in the form below to get started.</b></p>
                <FormRegisterNewUser />
            </Col>
            <Col className="top-row-right">

            </Col>
        </Row>
        {
        // TODO: Add in the background images for the bottom sections
        }
        <Row className="second-row">
            <div className={styles['flex-container']}>
                <h2><b>How It Works</b></h2>
                <p>MySoapBox™ is an online community panel, providing a rich and highly engaged pool of respondents. Our multi-mode recruiting approach includes a broad mix of methods and sources while our respondent-centric relationship models result in a highly engaged pool of respondents, focusing on today's touch points and poised to evolve with the technology.</p>

                    <Row className="icon-box-row">
                        <Col className="icon-box join-box"><h4>Join</h4><p>Sign up today to get access to our community panel.</p></Col>
                        <Col className="icon-box par-box"><h4>Participate</h4><p>Give your opinion on surveys and earn points.</p></Col>
                        <Col className="icon-box earn-box"><h4>Earn</h4><p>Redeem your points for gift cards!</p></Col>
                    </Row>


            </div>
        </Row>
        <Row className="third-row">
            <div className={styles['flex-container']}>
                <h2><b>Featured Rewards</b></h2>

                    <Row className="gift-card-row">
                        <Col className="card-box card-best-buy"></Col>
                        <Col className="card-box card-apple"></Col>
                        <Col className="card-box card-wings"></Col>
                        <Col className="card-box card-target"></Col>
                        <Col className="card-box card-starbucks"></Col>
                        <Col className="card-box card-amazon"></Col>
                    </Row>

            </div>
        </Row>
        <Row className="fourth-row">
            <div className={styles['flex-container']}>
                <h2><b>Why join MySoapBox</b></h2>

                    <Row className="why-join-row">
                        <Col className="icon-box icon-engage"><h4>Highest Engagement</h4></Col>
                        <Col className="icon-box icon-quick"><h4>Quick Payment Processing</h4></Col>
                        <Col className="icon-box icon-refer"><h4>Refer a Friend</h4></Col>
                        <Col className="icon-box icon-giveaway"><h4>Monthly Point Giveaway</h4></Col>
                    </Row>

            </div>
        </Row>
    </div>
    </div>
    </RegistrationLayout>

  );
};
