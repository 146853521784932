// _contexts/AuthContext.jsx
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

// LOGOUT CALL

import { fetchLogout }  from '../_apis/useLogoutUser';


import { SurveyListProvider } from './SurveyListProvider';
import { UserProfileProvider } from './UserProfileProvider';
import { NotificationsProvider } from './NotificationsProvider';
import { ScrollContext } from '../_contexts/ScrollContext';
import { SessionProvider } from '../_contexts/SessionProvider';
import { SessionLimitModal } from '../components/SessionLimitModal';

const AuthContext = createContext();



export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);


  const updateCurrentUser = useCallback(() => {
    const token = localStorage.getItem('token');
    const tokenExpiresAt = localStorage.getItem('token_expires_at');
  
    if (token && tokenExpiresAt) {
      if (Date.now() < parseInt(tokenExpiresAt, 10)) {
        setCurrentUser({ isLoggedIn: true, token: token, token_expires_at: tokenExpiresAt });
        // console.log("User updated in AuthContext:", { isLoggedIn: true, token: token, token_expires_at: tokenExpiresAt });
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expires_at');
      }
    }
  }, []);



  const logout = async () => {

    if (!currentUser || !currentUser.token) {
      return;
    }
    // console.log("currentUser in Logout function:", currentUser.token);

      try {
        const response = await fetchLogout(currentUser.token);
       
      
      
        if (response.status === 200) {
          localStorage.removeItem('token');
          localStorage.removeItem('token_expires_at');
        
          window.location.assign('https://www.mysoapbox.com/auth/logout');
      
          setTimeout(() => {
            setCurrentUser(null);

          }, 1000);
        } else {
          // Add additional logging for better understanding of the error
          console.error(`Logout failed with status: ${response.status}`);
      
          // You can also log the entire response to inspect it further
          console.error('Response:', response);
      
          window.location.assign('https://www.mysoapbox.com/auth/logout');
        }
      } catch (error) {
        window.location.assign('https://www.mysoapbox.com/auth/logout');
        console.error('Logout request error:', error);
      }
    
  };



  
  useEffect(() => {
    let isMounted = true;
    updateCurrentUser();
    if (isMounted) setLoading(false);
    return () => {
      isMounted = false;
    };
  }, [updateCurrentUser]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'token' || e.key === 'token_expires_at') {
        updateCurrentUser();
      }
    };
  
    window.addEventListener('storage', handleStorageChange);
  
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [updateCurrentUser]);

  

  const value = { currentUser, setCurrentUser, logout };
  const [targetElementRef, setTargetElementRef] = useState(null);

  return (
    <AuthContext.Provider value={value}>
      {!loading && (
        <ScrollContext.Provider value={[targetElementRef, setTargetElementRef]}>
        <SessionProvider>
        <SessionLimitModal />
        <SurveyListProvider>
          <UserProfileProvider>
          <NotificationsProvider>
          {children}
          </NotificationsProvider>
          </UserProfileProvider>
        </SurveyListProvider>
        </SessionProvider>
        </ScrollContext.Provider>
      )}
    </AuthContext.Provider>
  );
};
