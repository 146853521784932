// Path: src/_apis/surveyListApi.js
import axiosInstance from './axiosInstance';

// Function to filter the surveys
const filterSurveys = (survey) => {
  return (
    // survey.bid_length_of_interview <= 25 &&
    // survey.bid_incidence >=60 &&
    survey.respondent_pids.length === 0

  );
};



export const fetchSurveyList = async (authToken) => {
  let page = 1;
  let filteredData = [];
  let maxPages = Infinity;

  while (filteredData.length < 15 && page <= maxPages) {
    try {
      const now = new Date();
      const nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
      const startDate = new Date(nowUTC.getTime() - 2 * 24 * 60 * 60 * 1000); // Subtract x number of days from the current UTC time

      const startDateISOString = startDate.toISOString().split('T')[0];

      const response = await axiosInstance.get(`${process.env.REACT_APP_GET_SURVEY_LIST_PROVIDER_API_URL}`, {
        headers: {
          "Authorization": `Bearer ${authToken}`,
        },
        params: {
          limit: 50,
          updated_at_start_date: startDateISOString,
          page: page
        },
      });

      if (response.data && Array.isArray(response.data.data)) {
        const newFilteredData = response.data.data.filter(filterSurveys);
        filteredData = [...filteredData, ...newFilteredData];


        // If the paginator field is present in the response
        if(response.data.paginator) {

          maxPages = response.data.paginator.total_pages;
        }
      } else {

      }

      page += 1;
    } catch (error) {
      throw error;
    }
  }

  return filteredData;
};
