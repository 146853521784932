// UserProfileProvider
import React, { useState, useEffect } from "react";
import { UserProfileContext } from "./UserProfileContext";
import { useAuth } from "./AuthContext";
import { fetchUserProfile } from "../_apis/userProfileApi"; // Import the API call

export const UserProfileProvider = ({ children }) => {
  const [userProfile, setProfile] = useState(null);
  const [loading, setProfileLoading] = useState(true);
  const [error, setProfileError] = useState(null);
  const { currentUser } = useAuth();
  const [apiAttempted, setApiAttempted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser || !currentUser.token) return;
      // console.log("Fetching data in UserProfileProvider with currentUser:", currentUser);
  
      try {
        setProfileLoading(true);
        const data = await fetchUserProfile(currentUser.token);
        setProfile(data);
        setProfileLoading(false);
      } catch (error) {
        setProfileError(error);
        setProfileLoading(false);
      } finally {
        setApiAttempted(true); // Set apiAttempted to true after the API call
      }
    };
  
    if (currentUser && currentUser.token && currentUser.token_expires_at) {
      fetchData();
    }//eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser && currentUser.token, currentUser && currentUser.token_expires_at]);
  

  return (
    <UserProfileContext.Provider value={{ userProfile, loading: loading || !apiAttempted, error }}>
      {children}
    </UserProfileContext.Provider>
  );
};
