import React from "react";
import editProfileIcon from "../../assets/img/gear-filled.png";
import "./style.css";

export const NavEditProfile = () => {
  return (
    <div
    className={"icon-edit-profile "}
    style={{ backgroundImage: `url(${editProfileIcon})`}}> 
   
  </div>
  );
};
